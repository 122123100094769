<template>
  <div class="status-index">
    <div class="status-page-c">
      <fm-title title-text="状态管理" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
        <div class="t-s">
          <fm-select style="width: 300px;" placeholder="选择状态" absolute filterable :clearable="true" v-model="chooseGroupId">
            <fm-option v-for="item in groupList" :key="item.id" :value="item.id" :label="item.label"></fm-option>
          </fm-select>
          <div class="fm-tabs fm-tabs-norm">
          <div class="fm-tabs-nav">
            <div
              @click="showType = item.key"
              class="fm-tabs-nav-item"
              :class="{'fm-tabs-nav-active': item.key === showType}"
              v-for="item in showTypeList" :key="item.key">
              {{item.label}}
            </div>
          </div>
        </div>
        </div>
      </fm-title>
      <div class="status-blocks" v-if="chooseGroup">
        <div class="c-item" v-if="showType === 'status'">
          <status
            :status-group-id="chooseGroup.id"
            @dataUpdate="loadData(true)"
            :status-list="chooseGroup.statusList"></status>
        </div>
        <div class="c-item" v-if="showType === 'switch'">
          <status-switch
            :data-source-keys="dataSourceKeysMap[chooseGroup.groupKey]"
            :status-list="chooseGroup.statusList || []"
            :user-source-keys="dataSourceKeysMap.user"
            :status-group-id="chooseGroup.id"
            @dataUpdate="loadData(true)"
            :switch-list="chooseGroup.statusSwitchList"></status-switch>
        </div>
        <div class="c-item" v-if="showType === 'action'">
          <status-action
            :data-source-keys="dataSourceKeysMap[chooseGroup.groupKey]"
            :status-list="chooseGroup.statusList || []"
            :status-group-id="chooseGroup.id"
            :user-source-keys="dataSourceKeysMap.user"
            @dataUpdate="loadData(true)"
            :action-list="chooseGroup.statusActionList"></status-action>
        </div>
      </div>
    </div>
    <fm-form-dialog
      form-title="状态组"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="{}"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import {
  statusManageRequest
} from '@/api'

import {
  getDataSourceKeysConfig
} from './lib'

import status from './status'

import statusSwitch from './switch'
import statusAction from './action'

export default {
  components: {
    status,
    statusSwitch,
    statusAction
  },
  data () {
    return {
      showTypeList: [{
        key: 'status',
        label: '状态'
      },
      {
        key: 'switch',
        label: '状态切换'
      },
      {
        key: 'action',
        label: '动作'
      }],
      openDialog: false,
      dataSourceKeysMap: {},
      showType: 'status',
      groupList: [],
      chooseGroupId: null
    }
  },
  created () {
    this.$store.dispatch('loadUserList')
    this.$store.dispatch('loadRoleList')
    this.$store.dispatch('loadWorkerList')
    this.$store.dispatch('loadOrgList')
    this.loadData()
  },
  computed: {
    chooseGroup () {
      return this.groupList.find(v => v.id === this.chooseGroupId)
    },
    titleMenus () {
      return [{key: 'add', label: '新增'}, {key: 'reload', label: '清空缓存重新加载'}]
    },
    formParms () {
      return [{
        type: 'input',
        label: '状态组',
        key: 'label',
        check: {
          required: true
        }
      },
      {
        type: 'input',
        label: 'KEY',
        key: 'groupKey',
        check: {
          required: true
        }
      }]
    }
  },
  methods: {
    async formSubmit (data, resolve) {
      await statusManageRequest.updateGroup(data)
      this.openDialog = false
      this.loadData(true)
      resolve()
    },
    clickTitleMenu (action) {
      if (action === 'add') {
        this.openDialog = true
      } else {
        this.loadData(true)
      }
    },
    async loadData (reload) {
      let groupMap = {}
      if (reload) {
        groupMap = await statusManageRequest.reloadGroup()
      } else {
        groupMap = await statusManageRequest.getGroup()
      }
      this.groupList = Object.keys(groupMap).map(v => groupMap[v])
      this.groupList.forEach(v => {
        let statusMap = {}
        v.statusList.forEach(v1 => statusMap[v1.statusKey] = v1.label)
        v.statusSwitchList.forEach(v1 => {
          v1.statusLabel = statusMap[v1.statusKey]
          v1.newStatusLabel = statusMap[v1.newStatusKey]
        })
        v.statusActionList.forEach(v1 => {
          v1.statusLabel = statusMap[v1.statusKey]
        })
      })
      this.dataSourceKeysMap = getDataSourceKeysConfig(this.groupList)
    }
  }
}
</script>

<style scoped lang="less">
.status-index {
  .status-page-c {
    background: #fff;
    margin: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }
  .t-s {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .status-blocks {
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
    .c-item {
      width: 100%;
      height: 100%;
    }
  }
}
</style>