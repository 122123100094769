<template>
  <div class="status-c">
    <fm-title title-text="状态" :title-menus="[{key: 'add', label: '新增'}]" @clickTitleMenu="clickTitleMenu">
    </fm-title>
    <div class="c-table">
      <fm-table-new
        :columns="columns"
        :auto-height="true"
        ref="table"
        :data-list="statusList"
        emptyPlaceholder="-">
        <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
      </fm-table-new>
    </div>
    <fm-form-dialog
      form-title="状态"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import {
  statusManageRequest
} from '@/api'

export default {
  components: {
    TableActions
  },
  props: {
    statusList: { type: Array},
    statusGroupId: { type: Number }
  },
  data () {
    return {
      chooseData: {},
      openDialog: false
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    formParms () {
      return [{
        type: 'input',
        label: '状态',
        key: 'label',
        check: {
          required: true
        }
      },
      {
        type: 'input',
        label: 'KEY',
        key: 'statusKey',
        check: {
          required: true
        }
      },
      {
        type: 'select',
        label: '是否起始',
        key: 'isStart',
        selectDatas: [{key: '1', label: '是'}, {key: '0', label: '否'}],
        check: {
          required: true
        }
      },
      {
        type: 'select',
        label: '是否结束',
        selectDatas: [{key: '1', label: '是'}, {key: '0', label: '否'}],
        key: 'isEnd',
        check: {
          required: true
        }
      }]
    },
    columns () {
      return [{
        field: 'label',
        title: '状态'
      },
      {
        field: 'statusKey',
        title: 'KEY'
      },
      {
        field: 'isStart',
        title: '是否起始',
        render: (h, rowData) => {
          return h('div', rowData.isStart ? '是' : '-')
        }
      },
      {
        field: 'isEnd',
        title: '是否结束',
        render: (h, rowData) => {
          return h('div', rowData.isEnd ? '是' : '-')
        }
      },
      {
        title: '操作',
        field: 'actions',
        slot: 'actions',
        width: 100,
        fixed: 'right',
        search: false,
        export: false
      }]
    }
  },
  methods: {
    async formSubmit (data, resolve) {
      data.statusGroupId = this.statusGroupId
      await statusManageRequest.updateStatus(data)
      this.openDialog = false
      this.$emit('dataUpdate')
      resolve()
    },
    async tableAction (parm) {
      this.chooseData = Object.assign({}, parm.data, {
        isStart: parm.data.isStart ? '1' : '0',
        isEnd: parm.data.isEnd ? '1' : '0'
      })
      if (parm.action === 'edit') {
        this.openDialog = true
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该状态吗?'})
        if (result) {
          await statusManageRequest.delStatus(this.chooseData.id)
          this.$emit('dataUpdate')
        }
      }
    },
    clickTitleMenu () {
      this.chooseData = {}
      this.openDialog = true
    }
  }
}
</script>

<style scoped lang="less">
.status-c {
  width: 100%;
  height: 100%;
}
.c-table {
  width: 100%;
  height: calc(100% - 48px);
}
</style>