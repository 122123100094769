// 条件类型
export const getConditionTypeList = ({roleList, userList, workerList, orgList, dataSourceKeys}) => {
  return [{
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'orgIds',
    label: '机构数据权限限制',
    checkDataSource: 'value',
    keySelect: dataSourceKeys
  },
  {
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'orgIds',
    label: '职工数据权限限制',
    checkDataSource: 'value',
    keySelect: dataSourceKeys
  },
  {
    dataSource: 'data',
    label: '指定数据限制',
    checkDataSource: 'value',
    keySelect: dataSourceKeys
  },
  {
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'roleIds',
    label: '指定角色限制',
    checkDataSource: 'value',
    selectData: roleList
  },
  {
    checkType: 'in',
    dataSource: 'user',
    dataKey: 'userId',
    label: '指定用户限制',
    checkDataSource: 'value',
    selectData: userList
  },
  {
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'workerIds',
    label: '指定员工限制',
    checkDataSource: 'value',
    selectData: workerList
  },
  {
    checkType: 'mix',
    dataSource: 'user',
    dataKey: 'orgIds',
    label: '指定机构限制',
    checkDataSource: 'value',
    selectData: orgList
  }]
}

export const checkTypeList = [{
  key: '==',
  label: '相等'
},
{
  key: 'in',
  label: '在匹配值中'
},
{
  key: 'mix',
  label: '有交集'
}]

export const sourceDataList = [{
  key: 'data',
  label: '数据'
},
{
  key: 'user',
  label: '用户信息'
},
{
  key: 'value',
  label: '指定值'
}]

export const getDataSourceKeysConfig = (statusGroupList) => {
  let statusGroupMap = {}
  statusGroupList.forEach(v => {
    statusGroupMap[v.groupKey] = v
  })
  let data = {
    user: [{
      key: 'userId',
      label: '用户',
      tag: 'user'
    },
    // {
    //   key: 'roleId',
    //   label: '角色',
    //   tag: 'role'
    // },
    {
      key: 'roleIds',
      label: '角色',
      type: 'list',
      tag: 'role'
    },
    {
      key: 'workerIds',
      type: 'list',
      label: '职工',
      tag: 'worker'
    },
    {
      key: 'orgIds',
      type: 'list',
      label: '机构',
      tag: 'org'
    }],
    budgetYear: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.budgetYear.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'applyOrgIds',
      label: '填报科室',
      tag: 'org'
    },
    {
      key: 'preOrgIds',
      label: '初审科室',
      tag: 'org'
    },
    {
      key: 'finalOrgId',
      label: '终身机构',
      tag: 'org'
    }],
    budgetDetail: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.budgetDetail.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'budgetYearStatus',
      label: '年度预算状态',
      selectData: statusGroupMap.budgetYear.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'budgetApplyOrgId',
      label: '提报科室',
      tag: 'org'
    },
    {
      key: 'applyOrgId',
      label: '初审科室',
      tag: 'org'
    },
    {
      key: 'fgldWorkerId',
      label: '分管领导',
      tag: 'worker'
    },
    {
      key: 'finalOrgId',
      label: '终审科室',
      tag: 'org'
    }],
    purchaseBatch: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.purchaseBatch.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    }],
    purchaseBatchDetail: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.purchaseBatchDetail.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'purchaseBatchStatus',
      label: '采购批次状态',
      selectData: statusGroupMap.purchaseBatch.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'orgId',
      label: '提报科室',
      tag: 'org'
    },
    {
      key: 'busOrgId',
      label: '职能科室',
      tag: 'org'
    },
    {
      key: 'leadWorkerId',
      label: '分管领导',
      tag: 'worker'
    },
    {
      key: 'purchaseLeadWorkerId',
      label: '分管采购领导',
      tag: 'worker'
    },
    {
      key: 'deanWorkerId',
      label: '分管院长',
      tag: 'worker'
    }],
    purchaseMethodBatch: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.purchaseMethodBatch.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    }],
    purchaseMethodBatchDetail: [{
      key: 'status',
      label: '状态',
      selectData: statusGroupMap.purchaseMethodBatchDetail.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'purchaseMethodBatchStatus',
      label: '采购方式批次状态',
      selectData: statusGroupMap.purchaseMethodBatch.statusList.map(v => {
        return {key: v.statusKey, label: v.label}
      })
    },
    {
      key: 'type',
      label: '类型',
      selectData: [{key: 'mini', label: '金额2000元以下'}, {key: 'small', label: '金额2000元以上'}, {key: 'big', label: '大型采购'}]
    },
    {
      key: 'busOrgId',
      label: '职能科室',
      tag: 'org'
    },
    {
      key: 'submitOrgId',
      label: '提报科室',
      tag: 'org'
    },
    {
      key: 'purchaseMethodLeadWorkerId',
      label: '分管领导',
      tag: 'worker'
    },
    {
      key: 'deanWorkerId',
      label: '分管院长',
      tag: 'worker'
    }],
    assetsRepairApply: [{
      key: 'status',
      label: '状态'
    },
    {
      key: 'type',
      label: '类型',
      selectData: [{key: 'mini', label: '价值2000元以下'}, {key: 'norm', label: '价值2000元以上'}]
    },
    {
      key: 'busOrgId',
      label: '职能科室',
      tag: 'org'
    },
    {
      key: 'applyOrgId',
      label: '申请科室',
      tag: 'org'
    },
    {
      key: 'leadWorkerId',
      label: '分管领导'
    },
    {
      key: 'deanWorkerId',
      label: '分管院长'
    }],
    assetsScrapApply: [{
      key: 'status',
      label: '状态'
    },
    {
      key: 'applyOrgId',
      label: '申请科室',
      tag: 'org'
    },
    {
      key: 'leadWorkerId',
      label: '分管领导'
    },
    {
      key: 'busOrgId',
      label: '职能科室',
      tag: 'org'
    },
    {
      key: 'assetsLeadWorkerId',
      label: '分管资产领导'
    }]
  }
  return data
}